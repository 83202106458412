
import Vue from "vue";
import {
	isRequired,
	isNumber,
	isAfterToday,
	isAfterCompare,
	//@ts-ignore
} from "@/services/rule-services";
//@ts-ignore
import DatePicker from "@/components/Content/DatePicker.vue";
//@ts-ignore
import { getError } from "@/utils/resolveObjectArray";
//@ts-ignore
import { find, isNull, isUndefined, isEmpty } from "lodash";
//@ts-ignore
import { mapActions, mapGetters } from "vuex";
//@ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import Filterable from "@/components/Header/Tables/Filterable.vue";
// @ts-ignore
import { SortingOption } from "@/interfaces/paginated";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export default Vue.extend({
	name: "AssociationTable",
	props: {
		current_page: {
			type: Number,
		},
		next_page_url: {
			type: String,
		},
		path: {
			type: String,
		},
		per_page: {
			type: Number,
		},
		prev_page_url: {
			type: String,
		},
		to: {
			type: Number,
		},
		total: {
			type: Number,
		},
		line_item: {
			type: Object,
			default: function () {
				return {};
			},
		},
		headers: {
			type: Array,
			default: function () {
				return [];
			},
		},
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
		options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: {
		DatePicker,
		Filterable
	},
	data: () => ({
		//@ts-ignore
		img_default: require(`@/assets/default_image.webp`),
		sizes: [],
	}),

	created() {
		this.$nextTick(async () => {
			await this.setLoadingData(TypeLoading.loading);
			const sizes: Array<any> = await this.creativeSizes();
			this.sizes = sizes;
			await this.setLoadingData();
		});
	},

	mounted() {},

	computed: {
		currentPage: {
			set(val) {
				this.$emit("update-current-page", val);
			},
			get() {
				return this.current_page;
			},
		},
		...mapGetters("loading", ["isLoading"]),

		isReadOnly(){
			return this.readonly;
		},

		getRules() {
			return {
				isRequired,
				isNumber,
				isAfterToday,
				isAfterCompare,
			};
		},

		getMinDate() {
			if (!this.getLineItem?.start_date) return undefined;
			return this.moment(this.getLineItem?.start_date).format(
				DATE_TIME_FORMAT
			);
		},

		getMaxDate() {
			if (!this.getLineItem?.end_date) return undefined;
			return this.moment(this.getLineItem?.end_date).format(
				DATE_TIME_FORMAT
			);
		},

		getLineItem() {
			return this.line_item;
		},

		getLength() {
			return Math.ceil(this.total / this.per_page);
		},

		perPage() {
			return !isNaN(this.per_page) && isEmpty(this.per_page)
				? this.per_page
				: 25;
		},
	},

	methods: {
		...mapActions("creative", [
			"showCreative",
			"creativeSizes",
			"associateLineItemDelete",
			"associateLineItemUpdate",
		]),
		...mapActions("loading", ["setLoadingData"]),

		getErrors() {
			return this.$store.state.proccess.errors;
		},

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		getSizeName(size_id: any) {
			const size: any = find(this.sizes, function (s) {
				return String(s.id) == String(size_id);
			});
			return size?.value;
		},

		updatePaginate(data: Number) {
			this.$emit("update-paginate", data);
		},

		selectedOption(params: { options: SortingOption; filter: any }) {
			this.$emit("selected-option", {
				options: params.options,
				filter: params.filter,
			});
		},
		removeOption() {
			this.$emit("remove-option");
		},

		async deleteAssociation(id: number) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				await this.associateLineItemDelete(id);
				this.$emit("refresh");
				await this.setLoadingData();
				this.$emit("update-table");
			} catch (error) {
				await this.setLoadingData();
			}
		},

		async handleEnableEditCreative(id: number, idLine: number){
			this.$router.push({
				name: "CreativeEdit",
				params: { 
					id: id, 
					lineItem: {
						to: "LineItemEdit",
						id: idLine,
						section: 3
					} 
				}
			});
		},

		async handleEnableEdit(record: any) {
			record = await this.toggleEdit(record, true);
			return record;
		},

		async handleCancelEdit(record: any) {
			record = await this.toggleEdit(record, false);
			return record;
		},

		async parseAssociation(record: {
			id: Number;
			creative_id: Number;
			line_item_id: Number;
			start_date: any;
			end_date: any;
			active: Boolean;
		}) {
			return {
				id: record.id,
				creative_id: record.creative_id,
				line_item_id: record.line_item_id,
				start_date:
					isNull(record.start_date) || isUndefined(record.start_date)
						? null
						: this.dateFormatString(record.start_date),
				end_date:
					isNull(record.end_date) || isUndefined(record.end_date)
						? null
						: this.dateFormatString(record.end_date),
				active: !!record.active,
			};
		},

		async updateAssociate(record: any) {
			return await this.associateLineItemUpdate(
				await this.parseAssociation(record)
			);
		},

		async handleConfirmEdit(record: any) {
			if (isNull(record.start_date) || isUndefined(record.start_date))
				return;
			if (isNull(record.end_date) || isUndefined(record.end_date)) return;
			await this.setLoadingData(TypeLoading.loading);
			await this.updateAssociate(record)
				.then(async () => {
					record = this.toggleEdit(record, false);
					record.edited = false;
					await this.setLoadingData();
					return record;
				})
				.catch(async (err) => {
					await this.setLoadingData();
				});
		},

		async toggleEdit(record: any, edited: Boolean = false) {
			record.edited = edited;
			return record;
		},

		dateFormatString(date: any) {
			return isUndefined(date) || isNull(date)
				? ""
				: this.moment(date).format(DATE_TIME_FORMAT);
		},

		setDefaultImg(item: { creative_thumbnail: string }) {
			item.creative_thumbnail = this.img_default;
		}
	},

	watch: {},
});
