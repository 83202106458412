
import Vue from "vue";
import { mapActions } from "vuex";
//@ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
//@ts-ignore
import DatePicker from "@/components/Content/DatePicker.vue";
//@ts-ignore
import AssociationTable from "@/views/Admin/LineItem/Dependencies/Creatives/AssociationTable.vue";
//@ts-ignore
import { SortingOption } from "@/interfaces/paginated";
import {
	isRequired,
	isNumber,
	isAfterToday,
	isAfterCompare,
	//@ts-ignore
} from "@/services/rule-services";
import { isNull } from "lodash";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import AssociationTableBase from "./AssociationTableBase.vue";
//@ts-ignore
import { getHeaderAssoLineCreative } from "@/utils/CustomizerTables.ts";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export default Vue.extend({
	name: "AssociationForm",
	props: {
		line_item: {
			type: Object,
			default: function () {
				return {};
			},
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: { 
		CardAutocomplete, 
		DatePicker, 
		AssociationTable,
		AssociationTableBase
	},
	data: () => ({
		valid: true,
		options: {
			sort: "name",
			order: "asc",
		} as SortingOption,
		association: {
			form: {
				creative_id: null,
				start_date: null,
				end_date: null,
			},
			validations: {
				creative_id: [],
				start_date: [],
				end_date: [],
			},
			table: {
				headers: getHeaderAssoLineCreative()
			},
		},
		resources: {
			creatives: []
		},
		//@ts-ignore
		img_default: require(`@/assets/default_image.webp`),
	}),
	created() {
		this.$nextTick(async () => {
			await this.initializaListData();
		});
	},
	async mounted() {},
	computed: {
		getCreatives() {
			return this.$store.state.creative.creatives;
		},

		getCreativesData() {
			return this.resources.creatives;
		},

		getFilters() {
			return {
				creative_type_id: this.line_item?.line_item_type_id,
				advertiser_id: this.line_item?.advertiser_id,
				active: true,
			};
		},

		getRules() {
			return {
				isRequired,
				isNumber,
				isAfterToday,
				isAfterCompare,
			};
		},

		getToday() {
			return this.moment().format(DATE_TIME_FORMAT);
		},

		getMinDate() {
			if (this.line_item?.start_date) {
				return this.moment(this.line_item?.start_date).format(
					DATE_TIME_FORMAT
				);
			}
			return undefined;
		},
		isEnabledActivateButton(): Boolean {
			const association: Array<any> =
				this.line_item?.creative_associations || [];
			return association.length > 0;
		},

		getLineItemID(){
			return this.line_item.id;
		}
	},
	methods: {
		...mapActions("creative", [
			"list",
			"associateLineItem",
			"showCreative",
			"listCreativeAssociated"
		]),

		...mapActions("loading", ["setLoadingData"]),

		async initializaList() {
			await this.setLoadingData(TypeLoading.loading);
			await this.list({
				filters: this.getFilters,
				options: this.options,
			});
			this.association.table.items =
				await this.getAssociationTableItems();
			await this.setLoadingData();
		},

		async initializaListData() {
			await this.setLoadingData(TypeLoading.loading);
			this.resources.creatives = await this.listCreativeAssociated(this.getLineItemID);
			//this.association.table.items = await this.getAssociationTableItems();
			await this.setLoadingData();
		},

		async updateListData() {
			this.resources.creatives = [];
			this.resources.creatives = await this.listCreativeAssociated(this.getLineItemID);
		},

		async validate() {
			return await this.$refs.form.validate();
		},

		async getAssociationTableItems() {
			const creative_associations: Array<any> =
				this.line_item?.creative_associations || [];
			const items: Array<any> = [];

			creative_associations.map(async (a: any) => {
				//const creative: any = await this.showCreative(a.creative_id);
				const item: any = {
					id: a.id,
					creative_thumbnail_url:a?.creative_thumbnail || this.img_default,
					creative_id: a?.creative_id,
					creative_name: a?.creative_name,
					size: a?.creative_size,
					start_date: isNull(a?.start_date)
						? undefined
						: new Date(a?.start_date),
					end_date: isNull(a?.end_date)
						? undefined
						: new Date(a?.end_date),
					line_item_id: a?.line_item_id,
					active: !!a?.active,
					edited: false,
					valid: true,
				};
				items.push(item);
			});
			return items;
		},

		async addValidations(creative_id: any) {
			this.association.validations = {
				creative_id: [this.getRules.isRequired],
				start_date: [],
				end_date: [
					(v: any) => !!creative_id || this.$t("require-creative"),
				],
			};
		},

		async parsedData() {
			return {
				creative_id: this.association.form.creative_id,
				line_item_id: this.line_item.id,
				start_date: this.association.form.start_date
					? this.moment(
							String(this.association.form.start_date)
					  ).format(DATE_TIME_FORMAT)
					: undefined,
				end_date: this.association.form.end_date
					? this.moment(
							String(this.association.form.end_date)
					  ).format(DATE_TIME_FORMAT)
					: undefined,
			};
		},

		async handleSubmitAssociated() {
			if(this.readonly)return false;
			await this.addValidations(this.association.form.creative_id);
			if (!(await this.validate())) return;
			await this.setLoadingData(TypeLoading.loading);
			await this.associateLineItem(await this.parsedData())
			.then((response) => {
				this.setLoadingData();
				this.resetForm();
				this.$refs.table_ass.getPaginated();
			})
			.catch((err) => {
				this.setLoadingData();
			});
		},

		async resetValidations() {
			this.association.validations = {
				creative_id: [],
				start_date: [],
				end_date: [],
			};
			await this.validate();
		},

		async resetForm() {
			//this.$emit("refresh");
			this.resetValidations();
			this.association.form = {
				creative_id: null,
				start_date: null,
				end_date: null,
			};
			await this.updateListData();
		},

		/**
		 * Activate Line Item
		 * No tocar
		 */
		handleActivate() {
			this.$emit("line-item-activate");
		},

		/**
		 * Cancel
		 * No tocar
		 */
		async handleCancel() {
			this.$emit("cancel");
		},
	},

	watch: {
		async "association.form.creative_id"(val, old) {
			if(isNull(val)){
				await this.resetValidations();
			}else{
				await this.addValidations(val);
				await this.validate();
			}
		},
		async line_item(val, old) {
			await this.initializaList();
		},
	},
});
