
import Vue from "vue";
import TargetingTabItem from "./tab_contents/targetingTabItem.vue";
import TabItem from "./tab_items/TabItem.vue";
// @ts-ignore
import { getError } from "@/utils/resolveObjectArray";
import { debounce, isString, isArray, isEmpty, isUndefined } from "lodash";

import { mapActions } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { isRequired, isCountry } from "@/services/rule-services";

export default Vue.extend({
	name: "Geo",
	props: {
		geo: {
			type: Object,
			default: function() {}
		},
		data_variables: {
			type: Object,
			default: function() {
				return {};
			}
		},
		predicates: {
			type: Object,
			default: function() {
				return {};
			}
		},
		is_edit: {
			type: Boolean,
			default: false
		},
		isCampaignPMP:{
			type: Boolean,
			default: false
		},
		readonly:{
			type:Boolean,
			default: false
		}
	},
	components: {
		TargetingTabItem,
		TabItem
	},
	data: () => ({
		tab: "geo",
		countryTerm: null,
		cityTerm: null,
		regionTerm: null,
		latLongTerm: null,
		ignore_keys: ["city"]
	}),
	async created() {},
	async mounted() {
		await this.requiredResources();
	},
	computed: {
		getRules() {
			return {
				isRequired,
				isCountry,
			};
		},
		getErrors(){return this.$store.state.proccess.errors;},
	},
	methods: {
		
		...mapActions("loading", ["setLoadingData"]),
		
		getError(index: string) {return getError(this.getErrors, index);},
		
		async requiredResources() {
			if (this.is_edit) {
				const keys = Object.keys(this.geo);

				keys.forEach(async key => {
					if (this.ignore_keys.includes(key)) return;
					if (
						!isUndefined(this.geo[key]["targeting_terms"]) &&
						!isEmpty(this.geo[key]["targeting_terms"])
					) {
						await this.getComboData(key);
					}
				});
			}
		},

		itemIsArray(item: any) {
			return isArray(item);
		},

		itemIsString(item: any) {
			return isString(item);
		},
		getDisplayNameByID(key: string, id: any) {
			let displayName = "";

			const _data = this.data_variables[key].find(
				(app: any) => app.id === id
			);
			if (_data) {
				displayName = `${_data.value} (${id})`;
			}

			return displayName;
		},
		async getComboData(key: string) {
			try {
				let params = {};

				if (isEmpty(this.data_variables[key])) {
					switch (key) {
						case "city":
							params = {
								key: "prefix",
								value: "name_region"
							};

							break;

						case "country":
							params = {
								key: "prefix_3",
								value: "name"
							};

							break;

						case "lat_long_list":
							params = {
								key: "external_id",
								value: "name"
							};

							break;

						case "region":
							params = {
								key: "key",
								value: "name"
							};

							break;
					}

					await this.setLoadingData(TypeLoading.loading);

					this.$emit("update-data-var", {
						tab: this.tab,
						key: key,
						params: params,
						data: await this.dispatchTargetingKey(key, params)
					});

					await this.setLoadingData();
				}
			} catch (error) {
				console.error("getComboData", { key, error });
				await this.setLoadingData();
			}
		},
		async updateValues(key: string, items: Array<string>) {
			this.$emit("update-item-unique", {
				tab: this.tab,
				key: key,
				items: items
			});
		},
		adComma(key: any) {
			this.$emit("add-comma", {
				tab: this.tab,
				key: key
			});
		},
		async dispatchSearchByTerm(params: any) {
			return this.$store.dispatch("targeting/getSearchByTerm", params);
		},
		async dispatchTargetingKey(key: String, object?: any) {
			return this.$store.dispatch("targeting/getTargetingKey", {
				key: key,
				object: object
			});
		},

		clearHandler(key: any) {
			this.$emit("clear-app-site", { tab: this.tab, key: key });
		},

		syncData(key: String, term: String) {
			switch (key) {
				case "city":
					this.cityTerm = term;

					break;

				case "country":
					this.countryTerm = term;

					break;

				case "region":
					this.regionTerm = term;

					break;

				case "lat_long_list":
					this.latLongTerm = term;

					break;
			}
		},

		removeHandler(key: any, value: any, is_unique: Boolean = false) {
			this.$emit(is_unique ? "remove-item-unique" : "remove-item", {
				tab: this.tab,
				key: key,
				value: value,
				is_unique: is_unique
			});
		},

		async updateWatchByKey(
			key: String,
			val: Array<any>,
			old: Array<any>,
			is_unique: Boolean = false
		) {
			if (val.length > old.length) {
				const item = val.filter(function(o: any) {
					return !old.includes(o);
				});
				let dataItem = this.data_variables[`${key}`].find((val) => val.id == item[0]);
				this.$emit(is_unique ? "add-item-unique" : "add-item", {
					tab: this.tab,
					key: key,
					value: item[0],
					value_name: dataItem.value
				});
			}
			if (val.length < old.length) {
				const item = old.filter(function(o: any) {
					return !val.includes(o);
				});
				this.$emit(is_unique ? "remove-item-unique" : "remove-item", {
					tab: this.tab,
					key: key,
					value: item[0]
				});
			}
		}
	},

	watch: {
		async "geo.city.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("city", val, old, true);
		},
		async "geo.country.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("country", val, old, true);
		},
		async "geo.region.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("region", val, old, true);
		},
		async "geo.lat_long_list.value"(val: Array<any>, old: Array<any>) {
			await this.updateWatchByKey("lat_long_list", val, old);
		},

		cityTerm: debounce(async function(val: String, old: String) {
			if (val.length < 3) return;
			await this.setLoadingData(TypeLoading.loading);
			this.$emit("update-data-var", {
				tab: this.tab,
				key: "city",
				data: await this.dispatchSearchByTerm({
					term: val,
					key: "city",
					object: {
						key: "prefix",
						value: "name_region"
					}
				})
			});

			await this.setLoadingData();
		}, 500),

		countryTerm: debounce(async function(val: String, old: String) {
			if (val.length < 3) return;
			await this.setLoadingData(TypeLoading.loading);
			this.$emit("update-data-var", {
				tab: this.tab,
				key: "country",
				data: await this.dispatchSearchByTerm({
					term: val,
					key: "country",
					object: {
						key: "prefix_3",
						value: "name"
					}
				})
			});

			await this.setLoadingData();
		}, 500),

		regionTerm: debounce(async function(val: String, old: String) {
			if (val.length < 3) return;
			await this.setLoadingData(TypeLoading.loading);
			this.$emit("update-data-var", {
				tab: this.tab,
				key: "region",
				data: await this.dispatchSearchByTerm({
					term: val,
					key: "region",
					object: {
						key: "key",
						value: "name"
					}
				})
			});

			await this.setLoadingData();
		}, 500)
	}
});
